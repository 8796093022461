import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const GLOSSARY_LOCALES = {
	WOLTAIR_CZ: 'slovnik',
	WOLTAIR_PL: 'glossary',
	WOLTAIR_DE: 'glossary',
	WOLTAIR_IT: 'glossary'
}

export const GLOSSARY = GLOSSARY_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return GLOSSARY === param
}) satisfies ParamMatcher
